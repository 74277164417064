.eko-popup {
  .popup__children {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__description {
    width: 400px;
  }

  &__container {
    width: 300px;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;

    margin: 0 0 16px;
  }
}

@media (max-width: 350px) {
  .eko-popup {
    &__title {
      font-size: 22px;
    }
  }
}
