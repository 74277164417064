.activation-history-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__info {
    min-width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    background: #fff;
    align-items: center;
    border-bottom: 1.5px solid #f6f6f9;
  }

  &__nominal {
    justify-content: center;
    font-weight: 700;
  }

  &__date {
    justify-content: flex-end;
  }

  &__number {
    justify-content: flex-start;
  }

  &__text {
    font-size: 18px;
    color: #30303A;
  }
}

@media (max-width: 800px) {
  .activation-history-item {
    &__info {
      min-width: 60%;
    }
  }
}

@media (max-width: 500px) {
  .activation-history-item {
    &__text {
      font-size: 12px;
    }
  }
}
