@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.activation-eko {
  background-color: #F6F6F9;

  padding: 16px 0 96px;

  .second-input {
    &__label {
      color: #fff;
    }
  }

  .link {
    &__text {
      color: #fff;
    }
  }

  &__captcha-item {
    width: 47%;

    &:last-child {
      margin-top: 15px;
    }
  }

  &__captcha {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;
  }

  &__path {
    display: block;

    font-size: 11px;
    color: #757373;

    margin-bottom: 4px;
  }

  &__title {
    font-weight: 400;
    font-size: 50px;
    letter-spacing: -0.02em;
    color: #1D1D2D;

    margin: 0 0 12px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 25px;
    letter-spacing: -0.02em;
    color: #1D1D2D;

    margin: 0 0 25px;
  }

  &__content-container {
    display: flex;
    flex-direction: row;
  }

  &__line {
    border: none;
    border-top: 1px solid #F72E6C;
  }

  &__tip-content {
    display: flex;
    margin-left: 30px;
    flex-direction: column;
    color: #5F5F6B;
    max-width: 552px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 373px;
  }

  &__form-container {
    max-width: 430px;
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    box-sizing: border-box;
    background-color: #4661C4;
    border-radius: 16px;

    margin-right: 44px;
    padding: 48px;

    &_active {
      border-radius: 16px 16px 0 0;
    }
  }

  &__button {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 10px;

    &:hover {
      background-color: #E8EBF6;
    }

    .button {
      &__text {
        color: #4661C4;
      }
    }
  }

  &__form-payment {
    .activation-eko {
      &__form-container {
        border-radius: 0 0 16px 16px;
      }
    }
  }

  &__accept-rules {
    color: #5F5F6B;
    margin-top: 30px;
    font-size: 12px;
  }

  &__accept-rules-link {
    color: #4661C4;
  }

  &__tip {
    height: 320px;
    margin-top: 40px;
  }

  &__tip-list {
    font-family: 'Jost';
    padding-left: 20px;
    line-height: 22px;
  }

  &__tip-list-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__step {
    display: block;

    font-weight: 700;
    font-size: 24px;
    letter-spacing: -0.02em;
    color: #fff;

    margin-bottom: 16px;
  }

  &__checkbox-container {
    display: flex;
    justify-content: left;
    align-items: start;
    margin-bottom: 20px;
    flex-direction: column;
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    gap: 10px;
    margin-bottom: 12px;
    font-size: 12px;
  }

  &__checkbox-input {
    margin: 0 0 0 0;
  }

  &__checkbox-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-left: 5px;
  }

  &__tip-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &_mobile {
      display: none;
    }

    &_active {
      height: 435px;
    }

    &:nth-child(3) {
      margin-top: 35px;
    }
  }

  &__input {
    width: -webkit-fill-available;
    height: 48px;

    outline: none;
    border-radius: 8px;
    border: 1px solid #333;
    transition: all 0.3s ease-in;

    margin-top: 10px;

    font-size: 20px;

    padding-left: 16px;

    &:hover {
      border: 1px solid #F72E6C;
    }

    &:focus {
      border: 1px solid #F72E6C;
    }
  }

  &__rules {
    max-width: 769px;
    background: #fff;
    padding: 24px;
    margin-top: 40px;
    color: #5F5F6B;
    border-radius: 8px;

    &_mobile {
      display: none;
    }

    &_desktop {
      display: block;
    }
  }
}

@media (max-width: 995px) {
  .activation-eko {
    &__subtitle {
      margin-bottom: 35px;
    }

    &__title {
      font-size: 28px;
    }

    &__rules {
      max-width: 380px;

      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }

    &__tip-content {
      &_desktop {
        display: none;
      }
    }



    &__form-payment {
      .activation-eko {
        &__form-container {
          border-radius: 16px;
        }
      }
    }

    &__tip-container {
      &_mobile {
        display: block;
        width: 430px;
      }
    }

    &__tip {
      overflow: scroll;
    }

    &__form-container {
      width: 430px;
    }

    &__form {
      flex-direction: column-reverse;
      height: auto;
    }

    &__form {
      &:nth-last-child(2) {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 500px) {
  .activation-eko {
    &__tip-container_mobile {
      width: 350px;
      font-size: 12px;
    }

    &__checkbox-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &__checkbox {
      font-size: 16px;
    }

    &__form {
      align-items: center;
    }

    &__form-container {
      max-width: 360px;
      width: 350px;
      min-width: auto;
      margin-right: 0;
      padding: 24px;
      margin-left: -12px;

      .button {
        margin-top: 0;
      }
    }

    &__step2 {
      margin-top: 20px!important;
    }

    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__step {
      font-weight: 700;
      line-height: 130%;
    }

    &__captcha {
      //height: 100px;
    }
  }

  .rc-anchor-normal {
    width: 237px !important;
  }
}

