.accept-order {
  &__sum-container {
    margin-top: 10px;
    color: #30303A;
    font-family: 'Circe';
    font-size: 16px;
    font-weight: 600;
  }

  &_bold {
    font-weight: 700!important;
  }

  .button {
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;

    &__text {
      color: #FFF;
      font-family: 'Circe';
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__sum {
    color: #30303A;
    font-family: 'Circe';
    font-size: 24px;
    font-weight: 600;

    &_gray {
      color: #B0B7D3;
      font-size: 24px;
      font-weight: 400;
    }
  }


  .popup {
    &__children {
      margin-top: 0;
    }

    &__content {
      width: auto;
      height: auto;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__back-button {
    background: #E8EBF6;

    .button {
      &__text {
        color: #151519;
        font-family: 'Circe';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    &:hover {
      .button {
        &__text {
          color: #fff;
        }
      }
    }
  }

  &__container {
    width: 300px;

    text-align: center;
  }

  &__warning {
    color: #30303A;
    text-align: center;
    font-family: 'Circe';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__warning-container {
    margin-top: 20px;
    margin-bottom: 12px;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;

    margin: 0 0 16px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 30px;
    color: #F45384;
    width: 100%;
    text-align: center;
  }
}

.accept-order-list {
  &__container {
    max-height: 160px;
    overflow-y: auto;
    border-bottom: 1px solid #4661C4;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5px;

    &::-webkit-scrollbar {
      margin-right: -100px;
      width: 4px;
      border-radius: 4px;
      background-color: #E8EBF6;
    }

    &::-webkit-scrollbar-thumb {
      margin-right: -100px;
      right: 100%;
      border-radius: 4px;
      background-color: #858585;
    }
  }

  &__item {
    font-size: 16px;
    width: 100%;
    min-height: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    line-height: 26px;
    color: #B0B7D3;

    &_black {
      color: #30303A;
    }

    &_hidden {
      overflow: hidden;
      white-space: nowrap;
      background-color: inherit;
      width: 30%;
      transition: all .1s ease-in-out;
      text-overflow: ellipsis;
      position: absolute;
    }
  }

  &__validity_period {
    color: #F45384;
  }
}

.accept-order-item {
  &__cross-icon {
    width: 15px;
    height: 9px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nominal {
    font-size: 13px;
    color: #30303A;
  }

  &__markup {
    font-size: 13px;
    margin-top: 8px;
    margin-bottom: 9px;
    color: #F45384;
  }

  &_bold {
    font-weight: 700;
  }
}
@media (max-width: 460px) {
  .completed-order {
    .popup {
      &__content {
        max-width: 340px;
        min-height: auto;
      }
    }
  }

  .order-list {
    &__item {
      font-size: 14px;
    }
  }
}

@media (max-width: 350px) {
  .completed-order {
    &__title {
      font-size: 22px;
    }
  }
}
