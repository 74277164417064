.dashboard {
  height: fit-content;
  min-height: -webkit-fill-available;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #F6F6F9;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  &__showed-popup {
    padding-right: 15px;
  }

  .sargazarm {
    &__rules-and-pers {
      display: none;
    }
  }
}

@media (max-width: 800px) {
  .dashboard {
    &__container {
      width: calc(100% - 100px);

      padding: 0 50px;
    }
  }
}

@media (max-width: 500px) {
  .dashboard {
    &__container {
      width: calc(100% - 40px);

      padding: 0 20px;
    }
  }
}

@media (max-width: 390px) {
  .dashboard {
    .header {
      flex-direction: row;
    }
  }
}
