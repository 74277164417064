.counter {
  display: flex;
  align-items: center;

  padding: 4px;

  &__symbols {
    cursor: pointer;
    transition: all 0.2s linear;

    &_opacity {
      &_05 {
        opacity: 0.5;
        &:hover {
          scale: 1 !important;
          opacity: 1;
          transform: rotate(45deg);
          filter: hue-rotate(106deg)  brightness(1);
        }
      }
    }

    &:hover {
      scale: 1.2;
    }
  }

  &__value {
    width: 34px;

    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #30303A;
  }

  &__input{
    width: 34px;

    background-color: transparent;

    border: none;
    outline: none;

    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #30303A;

    margin: 0 5px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}
