.setting-popup {
  .popup__content_active{
    overflow-y: scroll;

    .popup__close {
      top: 15px !important;
      position: fixed !important;
      z-index: 2;
    }
  }

  &__filter-group {
    width: 100%;
  }

  &__button {
    color: #4661C4;
    margin-left: 20px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    position: relative;
  }

  &__button-title {
    position: absolute;
    z-index: 2;
    height: 100%;
    text-decoration: underline;
  }

  &__container {
    position: absolute;
    width: 100vw;
    height: calc(100vh + 70px);
    bottom: -70px;
    overflow-y: scroll;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content {
    background-color: #E8EBF6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 90px;
    padding-bottom: 20px;
  }

  &__filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px;

  }

  &__filter-item {
    padding: 3px 7px 3px 7px;
    border-radius: 4px;
    font-size: 12px;
    background-color: #fff;
    color: #93939a;
    width: fit-content;
    margin-right: 8px;
    margin-bottom: 8px;

    &_active {
      color: #fff;
      background-color: #4661C4;
    }
  }

  &__filter-title {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 400;
  };

  &__filter-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__button-component {
    .button__text {
      font-weight: 400;
    }
  }

  &__header {
    min-height: 70px;
    height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
