.completed-activation-card {
  .popup__content {
    width: auto;
    height: auto;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    max-width: 300px;
    width: 100%;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;

    margin-bottom: 32px;
  }
}
