.money-transfer-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 502px;
  text-align: center;

  &__image {
    width: 48px;
    height: 48px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
  }

  &__list {
    font-size: 18px;
    list-style-type: none;
    padding: 0;
    line-height: 21.6px;
    color: #5F5F6B;
    margin: 8px 0 24px;
  }

  &__subtitle {
    font-size: 18px;
    color: #5F5F6B;
  }
}

@media (max-width: 897px) {
  .money-transfer-alert {
    &__subtitle {
      font-size: 16px;
    }

    &__list {
      font-size: 16px;
    }
  }

  .money-transfer-alert-popup {
    .popup {
      &__content {
        max-width: calc(100% - 40px);
        max-height: 458px;
        border-radius: 16px;
        padding: 0 20px 20px;
      }

      &__close {
        top: 16px;
      }

      &__back-place-holder {
        width: auto;
        height: auto;
      }
    }

  }
}
