.completed-verified-email {
  max-width: 300px;
  width: 100%;

  text-align: center;

  margin: 0 auto;

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;

    margin: 0 0 32px;
  }
}