.tab-bar {
  padding-top: 100px;
  margin-bottom: 0;

  &__back {
    background-color: #f6f6f9;
    min-height: calc(100vh - 175px);
    padding: 24px calc((100vw - 1220px) / 2) 72px;
  }

  &__menu {
    width: auto;

    overflow-x: auto;
    overflow-y: hidden;

    margin: 0 0 -2px;
    padding: 0 calc((100vw - 1220px) / 2);

    &::-webkit-scrollbar {
      display: none;
    }

    &_hide {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__fixed-container {
    padding: 0;
    width: 100%;
  }

  &__container {
    display: flex;

    list-style: none;
  }

  &__item-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__subtitle {
    font-size: 11px;
    color: #4661C4;
    width: max-content;
    position: absolute;
    top: 20px;
  }

  &__line {
    width: 100%;
    height: 2px;

    background: rgba(95, 95, 107, 0.3);

    &[data-hide='true'] {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__item {
    font-size: 20px;
    line-height: 150%;
    color: rgba(29, 29, 45, 0.6);
    white-space: nowrap;

    border-bottom: 1px solid transparent;

    cursor: pointer;

    transition: color 0.3s linear, border-bottom 0.3s linear;

    padding-bottom: 12px;
    margin: 0 48px 0 0;

    &:last-child {
      margin-right: 0;
    }

    &_active {
      color: #4661C4;
      border-bottom: 2px solid #4661C4;
    }
  }

  &__children {
    max-width: 100vw;
    width: auto;
  }

  &__children-container {
    opacity: 0;

    &_active {
      opacity: 1;
      transition: all 0.5s linear;
    }
  }
}

@media (max-width: 1440px) {
  .tab-bar {
    &__back {
      padding: 24px 100px 72px;
    }

    &__menu {
      padding: 0 100px;
    }
  }
}

@media (max-width: 800px) {
  .tab-bar {
    &__back {
      padding: 24px 50px 72px;
    }

    &__item {
      font-size: 18px;
    }

    &__menu {
      padding: 0 20px;
    }
  }
}

@media (max-width: 630px) {
  .tab-bar {
    padding-bottom: 70px;
    padding-top: 80px;

    &__menu-container {
      display: none;
    }

    &__menu-container[data-mobile-visible='true'] {
      display: block;

      padding: 0;
    }

    &__back {
      padding: 24px 20px 72px;
    }

    &__item {
      font-size: 16px;

      padding-bottom: 0;
    }
  }
}

@media (max-width: 590px) {
  .tab-bar {
    &__item {
      font-size: 16px;

      padding: 0 0 8px;
      margin-right: 30px;
    }
  }
}

@media (max-width: 500px) {
  .tab-bar {
    &__item {
      font-size: 16px;

      margin-right: 15px;
      padding: 5px;
    }

    &__back {
      padding: 16px 20px;
    }

    &__container {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-content: center;

      padding: 0;
      margin: 0;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}

@media (max-width: 425px) {
  .tab-bar {
    &__item {
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .tab-bar {
    &__item {
      font-size: 12px;

      padding: 5px;
    }

    &__container {
      display: flex;
      justify-content: space-around;
      align-content: center;

      flex-direction: row;
      min-width: max-content;

      padding: 0 16px;
    }
  }
}
