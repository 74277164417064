.footer {
  width: 100%;

  position: relative;
  z-index: 2;

  &__phone-info-container {
    font-size: 15px;
    color: #30303a;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    &__phone {
      color: #007CFF;
    }
  }

  &__background {
    max-width: 1220px;
    width: calc(100% - 40px);

    padding: 53px 0;
    margin: 0 auto;
  }

  &__info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  &__container {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo-container {
    display: flex;
    justify-content: center;

    &_tablet, &_mobile {
      display: none;
    }
  }

  &__rules, &__years, &__pers {
    font-size: 10px;
    line-height: 22px;
    color: rgba(95, 95, 107, .7);
  }

  &__years {
    margin: 0;

    &_display-block {
      display: block;
    }
  }
  &__help-label {
    font-size: 13px;
    line-height: 150%;
    color: rgba(95, 95, 107, 0.7);

    margin: 0 0 8px;
  }

  &__help-container {
    display: flex;
    flex-wrap: wrap;

    max-width: 260px;
  }

  &__jivochat-container, &__telegram-container, &__whatsapp-container {
    display: flex;
    align-items: center;
  }

  &__jivochat-container {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  &__telegram-container {
    text-decoration: none;
    margin-bottom: 16px;
  }

  &__whatsapp-container {
    text-decoration: none;
  }

  &__jivochat-icon, &__telegram-icon, &__whatsapp-icon {
    margin-right: 8px;
  }
}

@media (max-width: 1024px) {
  .footer {
    margin-bottom: 30px;

    &__container {
      flex-direction: column;

      &_mobile {
        display: none;
      }
    }

    &__info-container {
      flex-direction: column-reverse;
    }

    &__background {
      padding: 30px 0;
    }

    &__rules-and-pers {
      text-align: left;

      &_auth {
        margin-bottom: 100px;
      }
    }

    &__logo-container {
      &_tablet {
        display: none;
      }

      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
        margin-bottom: 12px;
      }
    }

    &__logo {
      margin: 0;
    }

    &__help-label {
      text-align: center;
    }

    &__help {
      margin-bottom: 30px;
      margin-top: 30px;
    }

    &__help-container {
      max-width: 100%;
      flex-wrap: nowrap;
    }

    &__telegram-container {
      margin-right: 16px;
    }

    &__whatsapp-container {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 620px) {
  .footer {
    &__logo {
      width: 100%;
    }

    &__logo-container {
      &_mobile {
        width: 50%;
      }
    }

     &_auth {
       display: none !important;
     }
  }
}
