.dont-allow-auth {
  .popup {
    &__close {
      display: none;
    }

    &__children {
      align-items: center;
    }

    &__content {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }
  }

  &__image {
    max-width: 200px;
  }

  &__container {
    width: 100%;

    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 44px;
    line-height: 30px;
    color: #5290D9;

    margin: 0 0 16px;

    &_color-pink {
      color: #F45384;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: #2D2B37;

    margin: 0 0 24px;
  }
}

@media (max-width: 768px) {
  .dont-allow-auth {
    &__title {
      font-size: 22px;
    }

    &__subtitle {
      font-size: 14px;
    }

    &__description {
      font-size: 10px;
    }
  }
}
