.card-data {
  background-color: rgb(244, 83, 132);
  min-height: 100px;
  border-radius: 16px;
  margin-top: -16px;
  margin-left: -15px;
  margin-right: -15px;

  display: flex;
  align-items: center;
  z-index: 0;
  position: relative;
  justify-content: space-around;
  max-width: 460px;

  &__info-container {
    color: #FFF;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  &__info-data {
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
  }
}

@media (max-width: 500px) {
  .card-data {
    height: 70px;
    width: 100%;
    margin-left: -20px;
    margin-right: -8px;
  }
}

@media (max-width: 400px) {
  .card-data {
    height: 70px;
    width: 100vw;
    margin-left: -10px;
    margin-right: 0;
  }
}

