.ready-order-popup {
  .popup__children {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__link {
    font-weight: 700;
    cursor: pointer;
    color: #4661C4;
  }

  &__description {
    font-size: 18px;
  }
}

@media (min-width: 898px) {
  .ready-order-popup {
    .popup__children {
      width: 400px;
    }
  }
}

@media (max-width: 897px) {
  .ready-order-popup {
    .popup__children {
      width: auto;
    }
  }
}
