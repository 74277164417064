.deficiency-balance {
  .popup__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    width: 333px;

    text-align: center;

    margin: 0 auto;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;
  }

  &__description {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #30303A;

    margin: 0 0 16px;
  }
}

@media (max-width: 450px) {
  .deficiency-balance {
    &__container {
      width: 300px;
    }
  }
}

@media (max-width: 400px) {
  .deficiency-balance {
    &__container {
      width: 250px;
    }

    &__title {
      font-size: 22px;
    }

    &__description {
      font-size: 14px;
    }
  }
}