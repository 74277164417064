.commission-tip {
  .popup__content {
    max-width: 520px;
    width: 100%;
  }

  &__container {
    width: 100%;
  }

  &__header {
    max-width: 400px;

    display: flex;
    align-items: center;
    flex-direction: column;

    margin: 0 auto;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 130%;
    color: #1D1D2D;

    text-align: center;

    margin: 0;
  }

  &__ndfl {
    font-size: 18px;
    color: #F72E6C;

    text-align: center;

    margin: 16px 0 0;
  }

  &__info-container {
    border-top: 1px solid #E4E4EB;
    border-bottom: 1px solid #E4E4EB;

    padding: 8px 0;
    margin: 12px 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;

    font-size: 18px;
    color: #30303A;

    margin: 0 0 8px;

    &:last-child {
      margin: 0;
    }
  }

  &__name {
    display: -webkit-box;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__markup {
    color: #F45384;

    white-space: nowrap;
  }
}

.commission-tip[data-has-tax='false'] {
  .popup__children {
    overflow-y: hidden;
  }
}

@media (max-width: 897px) {
  .commission-tip {
    .popup__content {
      max-width: unset;
    }

    &__ndfl {
      text-align: center;
    }
  }

  .commission-tip[data-has-tax='false'][data-commission-list='false'] {
    .commission-tip__container {
      width: 100%;
    }
  }

  .commission-tip[data-has-tax='true'][data-commission-list='true'] {
    .commission-tip__header {
      max-width: unset;
    }
  }

  .commission-tip[data-commission-list='false'] {
    .commission-tip__header {
      max-width: unset;
    }
    
    .commission-tip__title {
      max-width: 400px;
    }
  }
}