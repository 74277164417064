.popup {
  width: 100vw;
  height: 100%;

  background-color: rgba(48, 48, 58, .9);

  overflow: hidden;

  display: none;
  align-items: center;
  justify-content: center;

  opacity: 0;

  position: fixed;
  top: 0;

  &_active {
    z-index: 4;

    display: flex;

    animation: 0.1s open 0.2s ease-in forwards;
  }

  &__content {
    max-height: calc(100% - 40px);

    background: #fff;
    border-radius: 16px;

    position: relative;

    box-sizing: border-box;

    transition: opacity 0.6s linear;

    opacity: 0.2;

    padding: 48px 48px 33px;
    margin: 20px;

    &_active {
      opacity: 1;
      position: relative;
    }
  }

  &__children {
    height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;

    padding-right: 20px;
    margin-right: -20px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;

    cursor: pointer;

    transition: all 0.3s linear;

    &:hover {
      transform: rotate(120deg);
    }
  }
}

@media (max-width: 897px) {
  .popup {
    &__content {
      width: 100vw;
      max-height: 100vh;
      height: 100vh;

      border-radius: 0;

      padding: 60px 20px 33px;
      margin: 0;
    }

    &__back-place-holder {
      background-color: #E8EBF6;
      position: fixed;
      top: 0;
      width: 100vw;
      height: 120vh;
      z-index: -1
    }

    &__children {
      margin-top: 30px;
    }

    &__close {
      top: 50px;
    }
  }
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
