.terms-of-use {
  .popup__content {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__container {
    max-width: 300px;
    max-height: 50%;
  }

  &__title {
    font-size: 18px;
    line-height: 130%;
    color: #1D1D2D;
    margin: 0 0 8px;
  }

  &__text {
    line-height: 121%;
    color: #5F5F6B;
    margin: 0 0 20px;
  }

  &__button {
    display: flex;
    align-items: center;

    outline: none;
    border: none;
    cursor: pointer;

    background-color: transparent;

    font-size: 14px;
    line-height: 131%;
    color: #4661C4;

    padding: 0;
  }

  &__arrow {
    transform: rotate(180deg);

    margin-right: 8px;
  }
}

@media (max-width: 480px) {
  .terms-of-use {
    .popup__children {
      margin-top: 70px;
      align-items: flex-start;
    }
  }
}
