.reject-transfer {


  &__container {
    width: 290px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__warning {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #FF4B40;

    margin: 6px 0;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #2D2B37;

    margin: 0 0 16px;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #30303A;

    margin: 0 0 16px;
  }
}