@font-face {
  font-family: 'Circe';
  src: url('../../../../../assets/fonts/Circe-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.money-transfer-reject {
  .popup {
    &__children {
      margin-top: 0;
    }

    &__content {
      width: auto;
      height: auto;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__container {
    width: 300px;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    color: #1D1D2D;
    text-align: center;
    font-family: 'Circe';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  &__subtitle {
    color: #30303A;
    text-align: center;
    font-family: 'Circe';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 460px) {
  .money-transfer-reject {
    .popup {
      &__content {
        max-width: 340px;
        min-height: auto;
      }
    }
  }
}

@media (max-width: 350px) {
  .money-transfer-reject {
    &__title {
      font-size: 22px;
    }
  }
}
