@font-face {
  font-family: 'Circe';
  src: url('../../../../../assets/fonts/Circe-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.completed-order {
  .popup {
    &__children {
      margin-top: 0;
    }

    &__content {
      width: auto;
      height: auto;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__container {
    width: 300px;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    color: #1D1D2D;
    text-align: center;
    font-family: 'Circe';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  &__subtitle {
    color: #30303A;
    text-align: center;
    font-family: 'Circe';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.order-list {
  &__container {
    max-height: 160px;
    overflow-y: auto;
    border-bottom: 1px solid #E4E4EB;
    border-top:  1px solid #E4E4EB;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 0 5px;

    &::-webkit-scrollbar {
      margin-right: -100px;
      width: 4px;
      border-radius: 4px;
      background-color: #E8EBF6;
    }

    &::-webkit-scrollbar-thumb {
      margin-right: -100px;
      right: 100%;
      border-radius: 4px;
      background-color: #858585;
    }
  }

  &__item {
    font-size: 16px;
    width: 100%;
    min-height: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    line-height: 26px;
    color: #B0B7D3;

    &_black {
      color: #30303A;
    }

    &_hidden {
      overflow: hidden;
      white-space: nowrap;
      background-color: inherit;
      width: 30%;
      transition: all .1s ease-in-out;
      text-overflow: ellipsis;
      position: absolute;
    }
  }

  &__validity_period {
    color: #F45384;
  }
}

@media (max-width: 460px) {
  .completed-order {
    .popup {
      &__content {
        max-width: 340px;
        min-height: auto;
      }
    }
  }

  .order-list {
    &__item {
      font-size: 14px;
    }
  }
}

@media (max-width: 350px) {
  .completed-order {
    &__title {
      font-size: 22px;
    }
  }
}
