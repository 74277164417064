.too-many-attempts {
  text-align: center;

  &__title {
    text-align: center;
    font-weight: 500;
  }

  &__icon {
    margin-bottom: 16px;
    width: 100px;
  }

  &__text {
    text-align: center;
    font-weight: 500;
  }
}
