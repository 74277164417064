.change-user-data {
  .popup {
    &__content {
      max-width: 460px;
    }
  }

  &__button {
    margin-top: 20px;
  }

  &__container {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  &__second-input {
    max-width: 400px;
  }
}

@media (max-width: 898px) {
  .change-user-data {
    .popup {
      &__content {
        max-width: 100%;
      }
    }
  }
}
