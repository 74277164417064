.order-card {
  min-height: 491px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__markup-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__by-with-all {
    width: 100%;
    margin-bottom: 16px;
    background: #F45384;
    border-radius: 8px;

    .button {
      &__text {
        font-weight: 500;
        font-size: 13px;
      }

      &:hover {
        background: #F45384!important;
      }
    }

    &:hover {
      background: #F45384!important;
    }

    &_bold {
      font-weight: 900;
    }
  }

  &__commission-info {
    border-radius: 8px;
    background: #EDFFF5;
    border: 1px solid #1BB961;
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 12px;
  }

  &__commission-warning {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 30px;
    color: #F45384;
    width: 100%;
    text-align: center;
  }

  &__commission-info-text {
    font-size: 12px;
    color: #2d2b37;
    font-family: Circe, serif;
    margin-left: 8px;
  }

  &__button-text {
    &_mobile {
      display: none;
    }
  }

  &__button-container {
    text-align: -webkit-right;
    display: flex;
    justify-content: right;
  }

  &__row {
    display: flex;
  }

  &__button {
    height: 100%;
    width: fit-content;

    .button {
      width: 50%;
      height: 100%;

      &__container {
        padding: 0 20px 0 20px;
      }

      &__text {
        color: #fff;
        font-size: 14px;
      }
    }

    &_desktop {
      display: flex;
    }

    &_mobile {
      display: none;
    }
  }

  &__continue-link-icon {
    width: 1.5em;
    margin-left: 5px;
  }

  &__continue-link {
    &_mobile {
      display: none;
    }
    &_desktop {
      display: flex;
    }
  }

  &__info-container {
    max-width: 228px;

    margin-right: 54px;

    &_mobile {
      display: none;
    }

    &_desktop {
      display: block;
    }
  }

  &__logo-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
  }

  &__logo {
    max-width: 229px;
    width: 100%;
    height: auto;

    border: 1px solid #E8EBF6;
    box-shadow: 0 14px 8px -9px #cfcfcf;
    border-radius: 8px;

    margin-bottom: 26px;
  }

  &__description-container {
    margin-bottom: 24px;
  }

  &__description {
    font-size: 12px;
    line-height: 121%;
    color: #5F5F6B;

    margin: 0 0 8px;
  }

  &__link {
    font-size: 13px;
    line-height: 131%;
    color: #4661C4;

    align-items: center;
    cursor: pointer;

    &_underline {
      margin-bottom: 0;
      margin-right: 24px;
      text-decoration: underline;
    }

    &_flex {
      display: flex;
    }
  }

  &__link-arrow {
    margin-left: 6px;
  }

  &__rules-container {
    display: flex;
    align-items: center;

    background: #E8EBF6;
    border-radius: 8px;

    cursor: pointer;

    padding: 12px;
    margin-bottom: 12px;

    &_mobile {
      display: none;
    }

    &_red {
      background-color: rgba(233, 172, 171, 0.3);
    }
  }

  &__clipboard {
    margin-right: 8px;
  }

  &__rules-title {
    font-size: 12px;
    line-height: 121%;
    color: #000000;
  }

  &__table {
    min-height: 100px;
    max-height: 340px;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
  }

  &__table-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 240px;
    padding-right: 5px;

    &::-webkit-scrollbar {
      margin-right: -100px;
      width: 4px;
      border-radius: 4px;
      background-color: #E8EBF6;
    }

    &::-webkit-scrollbar-thumb {
      margin-right: -100px;
      right: 100%;
      border-radius: 4px;
      background-color: #4661C4;
    }
  }

  &__nominals-container {
    max-width: 475px;
    width: 475px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 130%;
    color: #1D1D2D;

    margin: 0 0 8px;
  }

  &__issuer-info {
    margin: 0 0 4px;

    &_margin-bottom {
      margin-bottom: 8px;
    }

    &_mobile {
      display: none;
    }
  }

  &__info-label {
    font-size: 13px;
    line-height: 16px;
    color: rgba(48, 48, 58, 0.7);
  }

  &__date {
    font-size: 13px;
    line-height: 16px;
    color: #30303A;
  }

  &__link-issuer {
    font-size: 13px;
    line-height: 16px;
    color: #4661C4;
  }

  &__warning-icon {
    margin-right: 8px;
    width: 16px;
  }

  &__warning {
    font-size: 12px;
    line-height: 150%;
    color: #1BB961;

    display: flex;
    align-items: center;

    margin-bottom: 12px;

    &_mobile {
      display: none;
    }

    &_desktop {
      display: flex;
    }
  }

  &__total-container {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0 0;
  }

  &__total-container-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__total-label {
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    color: #30303A;
  }

  &__price-container {
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: center;
    align-items: flex-start;
  }

  &__commission-label {
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    color: #6F6F76;
  }

  &__commission-sum {
    font-size: 12px;
    color: #6F6F76;
    font-weight: 700;

    &_color-blue {
      font-weight: 400;
      color: #6F6F76;
    }
  }

  &__ndfl-label {
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    color: #6F6F76;
  }

  &__ndfl-sum {
    font-size: 12px;
    color: #6F6F76;
    font-weight: 700;

    &_color-blue {
      font-weight: 400;
      color: #6F6F76;
    }
  }

  &__sum {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #30303A;

    &_color-blue {
      font-weight: 400;
      color: #B0B7D3;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;

    .button {
      width: 255px;

      &__icon {
        margin-right: 0;
      }
    }
  }

  &__error {
    display: none;

    transition: all 0.3s ease;

    opacity: 0;

    &_active {
      justify-content: flex-end;
      display: flex;
      font-size: 12px;
      line-height: 150%;
      color: #F45384;
      opacity: 1;
      margin: 0 0 8px;
    }
  }
}

@media (max-width: 800px) {
  .order-card {
    &__row {
      padding-bottom: 20px;
    }

    &__info-container {
      max-width: 100%;

      display: none;

      margin-right: 0;

      &_mobile {
        display: block;
      }

      &_desktop {
        display: none;
      }
    }

    .arrow-table {
      display: block;
    }

    &__logo {
      margin-bottom: 0;
      margin-right: 12px;
      max-width: 181px;
    }

    &__warning {
      margin: 0 0 8px;
    }

    &__rules-container {
      margin-bottom: 24px;

      &_mobile {
        display: inline-flex;
        margin-bottom: 4px;
        font-size: 9px;
        padding: 7px;
      }
    }
  }
}

@media (max-width: 570px) {
  .order-card {
    &__description {
      max-width: 350px;
    }

    &__popup {
      .popup {
        &__content {
          background: linear-gradient(180deg, #F5F5F9 76.81%, rgba(245, 245, 249, 1) 100%);
        }
        &__back-place-holder {
          background: linear-gradient(180deg, #F5F5F9 76.81%, rgba(245, 245, 249, 1) 100%);
        }
      }

    }

    &__terms-container {
      margin-left: 18px;
    }

    &__rules-title_mobile {
      font-size: 9px;
      line-height: 100%;
      margin-left: 5px;
    }
  }
}

@media (max-width: 540px) {
  .order-card {
    &__continue-link {
      width: 50%;
      margin: 0;
      justify-content: center;
    }

    &__nominals-container {
      width: auto;
    }

    &__button-text {
      &_mobile {
        display: initial;
      }
    }

    &__link {
      &_underline {
        margin-bottom: 0px;
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 500px) {
  .order-card {
    max-height: 100%;

    &__clipboard {
      width: 24px;
    }

    &__popup {
      .popup__content {
        max-height: 100vh;
        height: 100vh;

        overflow: hidden;

        border-radius: 0;

        margin: 0;
      }

      .popup__children {
        overflow-y: scroll;
      }
    }
  }
}

@media (max-width: 460px) {
  .order-card {
    &__table-container {
      height: 250px;
    }
  }
}

@media (max-width: 424px) {
  .order-card {
    &__total-container-item {
      justify-content: center;
      margin-top: 0;
    }

    &__markup-container {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &__button {
      .button {
        width: 50%;
        height: 100%;
        border-radius: 0;

        &__text {
          font-size: 12px;
        }
      }
    }

    &__error {
      justify-content: center;
    }

    &__continue-link {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &__logo {
      margin-right: 0;
      max-width: 130px;
    }

    &__price-container {
      align-items: center;
    }

    &__total-container {
      text-align: center;
      justify-content: center;
    }

    &__terms-container {
      margin-left: 10px;
    }

    &__clipboard {
      margin-right: 4px;
    }

    &__button {
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-radius: 0;
      height: 55px;
      justify-content: center;

      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }

    &__warning {
      font-size: 11px;

      &_mobile {
        display: flex;
      }

      &_desktop {
        display: none;
      }
    }

    &__description {
      font-size: 11px;
    }

    &__button-container {
      display: flex;
      box-shadow: 2px 7px 5px 5px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 100vw;
      height: max-content;
      bottom: 0;
      left: 0;
      background: #fff;
    }

    &__scroll-container {
      margin-bottom: 30px;
    }

    &__issuer-info {
      &_mobile {
        display: block;
      }

      &_desktop {
        display: none;
      }
    }

    .arrow-table {
      &_active {
        opacity: 1 !important;
        visibility: initial !important;
      }
    }
  }
}

@media (max-width: 330px) {
  .order-card {
    &__popup {
      .popup__content {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
