.register {
  width: 100vw;
  height: 100vh;

  background-color: #fff;

  display: flex;
  justify-content: center;

  overflow-y: auto;

  transition: opacity 0.5s linear;

  position: fixed;
  top: 0;
  z-index: -1;

  opacity: 0;

  padding-top: 100px;

  &_active {
    opacity: 1;
    z-index: 4;
  }

  &__captcha-error {
    display: block;

    position: absolute;
    top: 80px;

    opacity: 0;
    transition: opacity 0.3s linear;

    font-size: 12px;
    line-height: 18px;
    color: #F7526D;

    &_active {
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 90px;

    cursor: pointer;

    transition: all 0.3s linear;

    &:hover {
      transform: rotate(120deg);
    }
  }

  &__logo {
    position: absolute;
    top: 20px;
    left: 90px;
  }

  &__container {
    max-width: 313px;
    width: 100%;

    height: 550px;

    padding-bottom: 100px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;

    margin: 0 0 8px;
  }

  &__info {
    font-size: 14px;
    line-height: 21px;
    color: rgba(48, 48, 58, 0.7);

    margin: 0 0 20px;

    &_color-pink {
      color: rgba(244, 83, 132, 0.7);
    }
  }

  &__captcha {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 0 25px;
  }

  &__captcha-item {
    width: 47%;

    &:last-child {
      margin-top: 5px;
    }
  }
}

@media (max-width: 900px) {
  .register {
    &__close {
      right: 54px;
    }

    &__logo {
      left: 45px;
    }
  }
}

@media (max-width: 740px) {
  .register {
    &__captcha {
      max-width: 100%;
    }

    &__captcha-item {
      width: 50%;

      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 560px) {
  .register {
    padding-top: 100px;

    &__logo {
      display: none;
    }

    &__close {
      right: 20px;
    }

    &__container {
      max-width: 100%;

      padding: 0 50px 150px;
    }

    &__captcha {
      max-width: 100%;
    }

    &__captcha-item {
      width: 47%;

      margin-right: 0;
    }
  }
}

@media (max-width: 410px) {
  .register {
    &__container {
      padding: 0 25px 150px;
    }
  }
}
